<template>
    <v-app-bar app color="secondary" class="elevation-0" id="header" height="64px">
        <v-toolbar-items>
            <v-btn class="logo" text exact href="/home" width="108px" aria-label="Site Logo" />
        </v-toolbar-items>

        <v-spacer />

        <v-toolbar-items class="hidden-sm-and-down">
            <v-btn text exact :to="{ name: 'home' }" aria-label="Link to Home page">Home</v-btn>
            <v-btn v-for="item in menuItems" :key="item.name" text :to="{ name: item.name }" :aria-label="`Link to ${item.text} page`">{{ item.text }}</v-btn>
            <Contact />
            <Search />
        </v-toolbar-items>

        <v-toolbar-items class="hidden-md-and-up">
            <v-dialog v-model="dialog" width="300px" content-class="elevation-0" overlay-opacity="0.95">
                <template v-slot:activator="{ on }">
                    <v-btn text v-on="on" aria-label="Mobile menu">
                        <v-icon>{{ mdiMenu }}</v-icon>
                    </v-btn>
                </template>
                <v-container class="text-center" align="center" justify="center">
                    <v-row>
                        <v-col cols="12">
                            <v-btn color="error" block large dark text @click="dialog = false">
                                <v-icon>{{ mdiClose }}</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-btn color="secondary" block large dark text @click="loadPage('home')" aria-label="Link to Home page">Home</v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-for="item in menuItems" :key="item.name">
                        <v-col cols="12">
                            <v-btn color="secondary" block large dark text @click="loadPage(item.name)" :aria-label="`Link to ${item.text} page`">{{ item.text }}</v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <Contact>
                                <template v-slot:button="{ on }">
                                    <v-btn color="secondary" block large dark text v-on="on">Contact Us</v-btn>
                                </template>
                            </Contact>
                        </v-col>
                    </v-row>
                </v-container>
            </v-dialog>
            <Search />
        </v-toolbar-items>
    </v-app-bar>
</template>

<script>
import { mdiMenu, mdiClose } from "@mdi/js";

export default {
    name: "Header",
    
    components: {
        Contact: () => import("@/components/Contact").then(m => m.default),
        Search: () => import("@/components/Search").then(m => m.default)
    },

    data() {
        return {
            mdiMenu: mdiMenu,
            mdiClose: mdiClose,
            dialog: false,
            menuItems: [
                {
                    name: "travel",
                    text: "Travel"
                },
                {
                    name: "photography",
                    text: "Photography"
                },
                {
                    name: "about",
                    text: "About"
                }
            ]
        };
    },

    methods: {
        async loadPage(page) {
            this.dialog = false;
            this.$router.push({ name: page });
        }
    }
};
</script>

<style lang="scss">
#header {
    z-index: 200;
}

.logo {
    background-image: url("/static/img/logo.png");
    background-size: cover;
}

.v-toolbar {
    background-image: linear-gradient(rgba($secondary_color, 0.74), rgba($secondary_color, 0.74)), url("/static/img/main.jpg");
    background-position: 50% 50%;
    background-size: cover;
    flex: 0 !important;
}

.v-toolbar__items .v-btn .v-btn__content {
    transition: 0.25s ease-in-out;
    backface-visibility: hidden;
}

.v-toolbar__items .v-btn:hover .v-btn__content {
    transition-duration: 0.25s;
    transform: scale(1.2);
    backface-visibility: hidden;
}
</style>